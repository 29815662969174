import { useEffect, useState, useRef } from "react";
import { getProducts, getUser } from "../api";
import { useNavigate } from "react-router";

import menuIcon from "../img/menu.svg";
import logo from "../img/logo.png";
import arrowIcon from "../img/arrow-right.svg";

import cartIcon from "../img/shopping-cart.svg";
import phoneIcon from "../img/phone-call.svg";
import searchIcon from "../img/search.svg";
import caseIcon from "../img/briefcase.svg";

function formatNumberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Catalog = () => {
  const nav = useNavigate();

  const [products, setProducts] = useState([]);
  const [activeCategory, setActiveCategory] = useState(0);
  const [cart, setCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showButton, setShowButton] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const menuContainersRefs = useRef([]);
  const navigationRef = useRef(null); // Ссылка на контейнер с навигацией

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCart(JSON.parse(localStorage.getItem("cart")));
    }

    async function fetchData() {
      const products = await getProducts();
      setProducts(products);
      const user = await getUser();
      localStorage.setItem("user", JSON.stringify(user));
    }

    fetchData();

    return () => {};
  }, []);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));

    let price = 0;
    cart.forEach((product) => {
      price += product.price * product.quantity * product.inBox;
    });
    setTotalPrice(parseInt(price));

    if (price > 0) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [cart]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const navigationRect = navigationRef.current.getBoundingClientRect();

  //     for (let i = 0; i < menuContainersRefs.current.length; i++) {
  //       const containerRect =
  //         menuContainersRefs.current[i].getBoundingClientRect();

  //       if (containerRect.top >= navigationRect.top) {
  //         setActiveCategory(i);
  //         break;
  //       }
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const handleCategoryClick = (index) => {
    setActiveCategory(index);
    menuContainersRefs.current[index].scrollIntoView({ behavior: "smooth" });
  };

  const goToCart = () => {
    nav("/cart");
  };

  return (
    <>
      <div
        className="container"
        onClick={() => {
          if (dropdownOpened) {
            setDropdownOpened(false);
          }
        }}
      >
        <div className="menu">
          <div className="logo">
            <img src={logo} />
          </div>

          <div className="menuIcon">
            <img
              src={menuIcon}
              onClick={() => {
                setDropdownOpened(!dropdownOpened);
              }}
            />
          </div>
        </div>
        {dropdownOpened ? (
          <div className="dropdown">
            <div
              className="item"
              onClick={() => {
                nav("/orders");
              }}
            >
              История заказов <img src={arrowIcon} />
            </div>
            {products
              .map((category, index) => ({ originalIndex: index, category }))
              .sort((a, b) => {
                if (a.category.categoryName === "Новинки") return -1;
                if (b.category.categoryName === "Новинки") return 1;
                return a.category.categoryName.localeCompare(
                  b.category.categoryName
                );
              })
              .map((sortedCategory, sortedIndex) => {
                const { category, originalIndex } = sortedCategory;
                const filteredProducts = category.products.filter(
                  (product) => product.price && parseInt(product.price) !== 0
                );
                if (filteredProducts.length === 0) return null;
                return (
                  <div
                    className="item"
                    onClick={() => {
                      nav("/category/" + originalIndex);
                    }}
                    key={sortedIndex}
                  >
                    {category.categoryName}
                    <img src={arrowIcon} />
                  </div>
                );
              })}
            <br />
          </div>
        ) : (
          ""
        )}
        {/* <div className="navigation" ref={navigationRef}>
          {products.map((category, index) => (
            <div
              key={index}
              className={`navItem ${activeCategory === index && "active"}`}
              id={`category${index}`}
              onClick={() => handleCategoryClick(index)}
            >
              {category.categoryName}
            </div>
          ))}
        </div> */}

        <div
          className="banner"
          onClick={() => handleCategoryClick(products.length - 1)}
        >
          <img src="/banner.jpg" />
        </div>

        {products
          .map((categoryy, index) => ({ ...categoryy, originalIndex: index }))
          .sort((a, b) => a.categoryName.localeCompare(b.categoryName))
          .map((category) => {
            const filteredProducts = category.products.filter(
              (product) => product.price && parseInt(product.price) !== 0
            );
            if (filteredProducts.length === 0) return null;
            return (
              <>
                <div
                  className="title"
                  id={`category${category.originalIndex}`}
                  onClick={() => {
                    nav("/category/" + category.originalIndex);
                  }}
                >
                  {category.categoryName}
                  <img src={arrowIcon} />
                </div>
                <div
                  className="menuContainer"
                  ref={(ref) =>
                    (menuContainersRefs.current[category.originalIndex] = ref)
                  }
                  key={category.originalIndex}
                >
                  {category.products
                    .filter((product) => {
                      if(product.price && parseInt(product.price) !== 0 && product.inStock && parseInt(product.inStock) !== 0 )
                        {
                        return product
                      }})
                    .slice(0, 9)
                    .map((product) => {
                    const inCart = cart.find(
                      (_product) => _product.id == product.id
                    );
                    /* if (!product.inStock || parseInt(product.inStock) === 0)
                    return (
                      <div className="menuItem">
                        <div className="info">
                          <img
                            src={`https://api-site.toyseller.site/api/image/${product.id}/${product.image}`}
                            alt="food"
                            class="picture"
                          />
                          <div class="name">{product.article}</div>
                          <div class="weight">
                            PM3: <br />
                            {product.inBox} шт
                            <br />
                          </div>
                        </div>
                        <div class="price">Нет в наличии</div>
                      </div>
                    );*/
                    return (
                      <div className="menuItem">
                        <div className="info">
                          <img
                            onClick={() => {
                              localStorage.setItem(
                                "product",
                                JSON.stringify({
                                  ...product,
                                  inCart: inCart ? inCart.quantity : 0,
                                })
                              );
                              nav(`/product/${product.id}`);
                            }}
                            src={`https://api-site.toyseller.site/api/image/${product.id}/${product.image}`}
                            alt="food"
                            class="picture"
                          />
                          <div class="name">{product.article}</div>
                          <div class="weight">PM3: {product.inBox} шт</div>
                        </div>
                        {inCart ? (
                          <div class="add catalog_counter">
                            <div
                              class="cic-minus"
                              onClick={() => {
                                let minusAmount = 1;
                                if (
                                  parseInt(product.inBox) >=
                                  parseInt(inCart.quantity * product.inBox)
                                ) {
                                  minusAmount = 1 / (parseInt(product.inBox) / parseInt(product.inPackage));
                                } else if (
                                  parseInt(product.inBox) + parseInt(product.inTheBox) <=
                                  parseInt(inCart.quantity * product.inBox)
                                ) {
                                  minusAmount = parseInt(product.inTheBox) / parseInt(product.inBox);
                                }

                                const newQuantity = inCart.quantity.toFixed(3) > minusAmount.toFixed(3) ? parseFloat(inCart.quantity - minusAmount) : 0;

                                if (
                                  newQuantity > 0
                                ) {
                                  setCart(
                                    cart.map((_product) => {
                                      if (_product.id === product.id) {
                                        return {
                                          ..._product,
                                          quantity: newQuantity,
                                        };
                                      }
                                      return _product;
                                    })
                                  );
                                } else {
                                  setCart(
                                    cart.filter(
                                      (_product) => _product.id != product.id
                                    )
                                  );
                                }
                              }}
                            >
                              <svg
                                class="svg"
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0"
                                y="0"
                                viewBox="0 0 341.333 341.333"
                                style={{
                                  "enable-background": "new 0 0 512 512",
                                }}
                                xmlSpace="preserve"
                              >
                                <rect
                                  y="149.333"
                                  width="341.333"
                                  height="42.667"
                                  fill="#f6f6f6"
                                ></rect>
                              </svg>
                            </div>
                            <div class="amount">
                              {
                                parseInt(inCart.quantity * product.inBox) % product.inPackage !== 0
                                ? Math.ceil(inCart.quantity * product.inBox)
                                : parseInt(inCart.quantity * product.inBox)
                              }
                            </div>
                            <div
                              class="cic-plus"
                              onClick={() => {
                                if (
                                  inCart.quantity <
                                  product.inStock *
                                    (parseInt(product.inTheBox) /
                                      parseInt(product.inBox))
                                ) {
                                  let incrementAmount = 1;
                                  if (
                                    parseInt(product.inBox) >
                                    parseInt(inCart.quantity * product.inBox)
                                  ) {
                                    incrementAmount =
                                      1 /
                                      (parseInt(product.inBox) /
                                        parseInt(product.inPackage)); //parseInt(product.inPackage)
                                  }
                                  setCart(
                                    cart.map((_product) => {
                                      if (_product.id === product.id) {
                                        return {
                                          ..._product,
                                          quantity: parseFloat(
                                            (
                                              _product.quantity +
                                              incrementAmount
                                            ).toFixed(2)
                                          ),
                                        };
                                      }
                                      return _product;
                                    })
                                  );
                                }
                              }}
                            >
                              <svg
                                class="svg"
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0"
                                y="0"
                                viewBox="0 0 341.4 341.4"
                                style={{
                                  "enable-background": "new 0 0 512 512",
                                }}
                                xmlSpace="preserve"
                              >
                                <polygon
                                  points="192,149.4 192,0 149.4,0 149.4,149.4 0,149.4 0,192 149.4,192 149.4,341.4 192,341.4 192,192 341.4,192 341.4,149.4"
                                  fill="#f6f6f6"
                                  class=""
                                ></polygon>
                              </svg>
                            </div>
                          </div>
                        ) : (
                          <div
                            class="price"
                            onClick={() => {
                              localStorage.setItem(
                                "product",
                                JSON.stringify({
                                  ...product,
                                })
                              );
                              nav("/product/" + product.id);
                            }}
                          >
                            {formatNumberWithSpaces(Number(product.price))} ₽
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}

        <div className="bottomBar">
          <div className="icon">
            <img
              src={phoneIcon}
              onClick={() => {
                nav("/contact");
              }}
            />
          </div>
          <div className="icon">
            <img
              src={caseIcon}
              onClick={() => {
                nav("/terms");
              }}
            />
          </div>
          <div className="icon">
            <img
              src={searchIcon}
              onClick={() => {
                nav("/search");
              }}
            />
          </div>
          <div className="icon">
            <img
              src={cartIcon}
              onClick={() => {
                nav("/cart");
              }}
            />
          </div>
        </div>

        {totalPrice > 0 && (
          <div class="go-to-order-wrap ">
            Заказ на {formatNumberWithSpaces(totalPrice)} ₽
          </div>
        )}
        {/* 
        {showButton && <div class="mainButton" onClick={goToCart}>Оформить заказ</div>} */}
      </div>
      <footer className="footer"
        style={{
          marginBottom: 120,
        }}
      >
        <img src={logo} />
        <p className="footer-text">   
          Мы выбрали
          {' '}
          <a href="https://spruton.shop/" target="_blank" rel="noreferrer">СПРУТОН МАРКЕТ</a>
          {' '}
          для создания магазина
        </p>
        <div className="phone" style={{
              display:  "flex",
              flexDirection:  "column",
              color: "white",
              gap: "10px",
              alignItems: 'center',
          }}>
            Техническая поддержка: 
            <a onClick={() => {
                window.open("tel:+79786121068", "_blank")
            }}>
              +79786121068
            </a>
            <a onClick={() => {
                window.open("mailto:support@spruton.shop", "_blank")
            }}>
              support@spruton.shop
            </a>
          </div>
      </footer>
    </>
  );
};

export default Catalog;
