import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { newOrder } from "../api";
import arrowIcon from "../img/arrow-left.svg";
import { toast } from "react-hot-toast";

function formatNumberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Order = () => {
  const nav = useNavigate();
  const [cart, setCart] = useState([]);
  const [deliveryData, setDeliveryData] = useState("2");
  const [paymentData, setPaymentData] = useState("3");
  const [totalPrice, setTotalPrice] = useState(0);
  const [data, setData] = useState({
    name: "",
    phone: "",
    address: "",
    comment: "",
    companyName: "",
    inn: "",
  });
  const [canOrder, setCanOrder] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCart(JSON.parse(localStorage.getItem("cart")));
    }

    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setData({
        name: user.name,
        phone: user.phone,
        address: user.address,
        companyName: user.company,
        inn: user.inn,
      });
    }
  }, []);

  const goBack = () => {
    nav("/cart");
  };

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));

    let price = 0;
    cart.forEach((product) => {
      price += product.price * product.quantity * product.inBox;
    });
    setTotalPrice(parseInt(price));
  }, [cart]);

  useEffect(() => {
    if (data.name && data.phone && data.companyName && data.inn.length == 12) {
      if (deliveryData == "2") {
        if (!data.address) {
          return () => {};
        }
      }
    } else {
    }
  }, [data, deliveryData]);

  const createOrder = async () => {
    console.log("createOrder");
    if (!canOrder) return;
    setCanOrder(false);
    const order = {
      ...data,
      delivery: deliveryData == "1" ? "Самовывоз" : "Курьером",
      payBy:
        paymentData == "3"
          ? "Наличными"
          : paymentData == "4"
          ? "Картой"
          : "Счет",
      products: cart.map((product) => ({
        id: product.id,
        name: product.article,
        quantity: product.quantity,
        price: parseInt(product.price),
        inBox: product.inBox,
      })),
    };

    await newOrder(order);

    localStorage.removeItem("cart");
    setCart([]);
    toast.success(
      "Заказ оформлен, наш менеджер в ближайшее время с Вами свяжется"
    );
    setTimeout(() => {
      nav("/");
      setData({
        name: "",
        phone: "",
        address: "",
        comment: "",
        companyName: "",
        inn: "",
      });
    }, 3000);
  };

  return (
    <>
      <div class="container-order-data">
        <div class="order-form">
          <img
            src={arrowIcon}
            style={{
              marginBottom: 12,
            }}
            onClick={() => {
              nav("/cart");
            }}
          />
          <div class="order-form-select-group">
            <div class="order-choise-btn-label">
              <select
                class="choise-btn"
                value={deliveryData}
                onChange={(e) => {
                  setDeliveryData(e.target.value);
                }}
              >
                <option value="1">Самовывоз</option>
              </select>
            </div>
            <div
              class="order-choise-btn-label"
              value={paymentData}
              onChange={(e) => {
                setPaymentData(e.target.value);
              }}
            >
              <select class="choise-btn">
                <option value="3">Наличными</option>
              </select>
            </div>
          </div>

          {/* {deliveryData == "1" && (
            <div class="delivery-description">
              Адрес самовывоза: Ул. Тестовская, д. 1
            </div>
          )} */}

          <p class="order-data-page-title">Получатель</p>

          <div class="form-group-section">
            <div class="form-group">
              <input
                type="text"
                class="formInput"
                value={data.name}
                onChange={(e) => {
                  setData({
                    ...data,
                    name: e.target.value,
                  });
                }}
                placeholder="ФИО"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="formInput"
                placeholder="Название компании"
                value={data.companyName}
                onChange={(e) => {
                  setData({
                    ...data,
                    companyName: e.target.value,
                  });
                }}
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="formInput"
                placeholder="ИНН"
                value={data.inn}
                onChange={(e) => {
                  setData({
                    ...data,
                    inn: e.target.value,
                  });
                }}
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="formInput"
                placeholder="Телефон"
                value={data.phone}
                onChange={(e) => {
                  setData({
                    ...data,
                    phone: e.target.value,
                  });
                }}
              />
            </div>
            {deliveryData == "2" && (
              <div class="form-group">
                <input
                  type="text"
                  class="formInput"
                  placeholder="Адрес доставки"
                  value={data.address}
                  onChange={(e) => {
                    setData({
                      ...data,
                      address: e.target.value,
                    });
                  }}
                />
              </div>
            )}
            <div class="form-group">
              <input
                type="text"
                class="formInput"
                placeholder="Комментарий"
                onChange={(e) => {
                  setData({
                    ...data,
                    comment: e.target.value,
                  });
                }}
                value={data.comment}
              />
            </div>
          </div>

          <div class="delivery-description text-red"></div>
        </div>
        <div class="go-to-order-wrap">
          Заказ на {formatNumberWithSpaces(totalPrice)} ₽
        </div>
        <div class="mainButton" onClick={createOrder}>
          Оформить заказ
        </div>
      </div>
    </>
  );
};

export default Order;
