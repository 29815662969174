import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

function formatNumberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Product = () => {
  const nav = useNavigate();
  const [cart, setCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [product, setProduct] = useState(
    JSON.parse(localStorage.getItem("product"))
  );
  const [inCart, setInCart] = useState({
    quantity: 0,
  });

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCart(JSON.parse(localStorage.getItem("cart")));

      const inCart = JSON.parse(localStorage.getItem("cart")).find(
        (item) => item.id === product.id
      );
      if (inCart && inCart.quantity) {
        setInCart(inCart);
      }
    }

    const swiperEl = document.querySelector("swiper-container");

    const swiperParams = {
      slidesPerView: 1,
      navigation: true,
      scrollbar: true,
      autoHeight: true,
    };

    Object.assign(swiperEl, swiperParams);

    swiperEl.initialize();
  }, []);

  const goBack = () => {
    nav("/");
  };

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));

    let price = 0;
    cart.forEach((product) => {
      price += product.price * product.quantity * product.inBox;
    });
    setTotalPrice(parseInt(price));
  }, [cart]);

  return (
    <>
      <div class="container-food">
        <div class="foodPage">
          <div
            class="cross-item"
            onClick={async () => {
              // go back
              window.history.back();
              window.Telegram.WebApp.BackButton.hide();
            }}
          >
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="49"
                height="49"
                rx="24.5"
                fill="#413D4F"
                fill-opacity="0.5"
              ></rect>
              <path
                d="M30 19L19 30M19 19L30 30"
                stroke="white"
                stroke-width="2.0625"
                stroke-linecap="round"
              ></path>
            </svg>
          </div>
          <div class="data-food">
            <swiper-container init="false">
              <swiper-slide>
                <img
                  src={`https://api-site.toyseller.site/api/image/${product.id}/${product.image}`}
                  alt="food"
                  class="food"
                />
              </swiper-slide>
              {product.review && (
                <swiper-slide>
                  <iframe
                    style={{
                      marginTop: 12,
                    }}
                    width="100%"
                    height="315"
                    src={
                      "https://www.youtube.com/embed/" +
                      product.review
                        .replace("https://www.youtube.com/watch?v=", "")
                        .split("&")[0]
                    }
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </swiper-slide>
              )}
              {/* {product.otherImages.filter(item => item != "").map((slide) => (
              <swiper-slide>
                <img src={`https://api-site.toyseller.site/api/image/${product.id}/${slide}`} alt="food"
              class="food" />
              </swiper-slide>
            ))} */}
            </swiper-container>

            <div class="title-food">
              <span class="text-title-food">{product.article} </span>
            </div>
            <div class="description-food">
              {product.inPackage && (
                <>
                  <div> В упаковке: {product.inPackage} шт.</div>
                </>
              )}
              {product.inTheBox && (
                <>
                  <div>В коробке: {product.inTheBox} шт.</div>
                </>
              )}
              {product.inBox && (
                <>
                  <div style={{display:"flex",gap:"5px",alignItems:"center"}}>PM3: {product.inBox} шт. <div onClick={() => toast("Рекомендованный минимальный заказ",{duration:4000})}><svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"/> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"/> <circle cx="12" cy="16" r="1" fill="#ffffff"/> </g>

</svg></div></div>
                </>
              )}
              <br />
              {product.keyWords.length === 0 || product.keyWords[0] === "" ? (
                ""
              ) : (
                <div>
                  Ключевые слова:{" "}
                  {product.keyWords.map((str, index) => (
                    <span key={index}>
                      {str}
                      {index !== product.keyWords.length - 1 && ", "}
                    </span>
                  ))}
                </div>
              )}
              <br />
              {product.description}
            </div>
          </div>
        </div>

        <div class="product-count-wrap">
          <div class="pcw-btn-group">
            <div class="price-product-card">
              <span class="js-product-price">
                {formatNumberWithSpaces(product.price)}
              </span>
               ₽
            </div>
            {inCart.quantity > 0 ? (
              <div class="pcw-count-wrap">
                <div
                  class="pcw-minus"
                  onClick={() => {
                    if (
                      inCart.quantity <=
                      parseInt(product.inStock) *
                        (parseInt(product.inTheBox) / parseInt(product.inBox))
                    ) {
                      let minusAmount = 1;
                      if (
                        parseInt(product.inBox) >=
                        parseInt(inCart.quantity * product.inBox)
                      ) {
                        minusAmount = 1 / (parseInt(product.inBox) / parseInt(product.inPackage));
                      } else if (
                        parseInt(product.inBox) + parseInt(product.inTheBox) <=
                        parseInt(inCart.quantity * product.inBox)
                      ) {
                        minusAmount = parseInt(product.inTheBox) / parseInt(product.inBox);
                      }
                      
                      const newQuantity = inCart.quantity.toFixed(3) > minusAmount.toFixed(3) ? parseFloat(inCart.quantity - minusAmount) : 0;

                      if (newQuantity > 0) {
                        const newCart = cart.map((item) => {
                          if (item.id === product.id) {
                            item.quantity = newQuantity;
                          }
                          return item;
                        });
                        setCart(newCart);
                        setInCart({
                          ...inCart,
                          quantity: newQuantity,
                        });
                      } else {
                        const newCart = cart.filter((item) => item.id !== product.id);
                        setCart(newCart);
                        setInCart({});
                      }
                    } else {
                      const newCart = cart.filter((item) => item.id !== product.id);
                      setCart(newCart);
                      setInCart({});
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0"
                    y="0"
                    viewBox="0 0 341.333 341.333"
                    style={{ "enable-background": "new 0 0 512 512" }}
                    xmlSpace="preserve"
                    class=""
                  >
                    <rect
                      y="149.333"
                      width="341.333"
                      height="42.667"
                      fill="#ffffff"
                    ></rect>
                  </svg>
                </div>
                <div class="pcw-count">
                {
                  parseInt(inCart.quantity * product.inBox) % product.inPackage !== 0
                  ? Math.ceil(inCart.quantity * product.inBox)
                  : parseInt(inCart.quantity * product.inBox)
                }
                </div>
                <div
                  class="pcw-plus"
                  onClick={() => {
                    if (
                      inCart.quantity <
                      product.inStock *
                        (parseInt(product.inTheBox) / parseInt(product.inBox))
                    ) {
                      let incrementAmount = 1;
                      if (
                        parseInt(product.inBox) >
                        parseInt(inCart.quantity * product.inBox)
                      ) {
                        incrementAmount =
                          1 /
                          (parseInt(product.inBox) /
                            parseInt(product.inPackage)); //parseInt(product.inPackage)
                      }

                      const newCart = cart.map((item) => {
                        if (item.id === product.id) {
                          item.quantity += incrementAmount;
                        }
                        return item;
                      });
                      setCart(newCart);
                      setInCart({
                        ...inCart,
                        quantity: inCart.quantity + incrementAmount,
                      });
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0"
                    y="0"
                    viewBox="0 0 341.4 341.4"
                    style={{ "enable-background": "new 0 0 512 512" }}
                    xmlSpace="preserve"
                    class=""
                  >
                    <polygon
                      points="192,149.4 192,0 149.4,0 149.4,149.4 0,149.4 0,192 149.4,192 149.4,341.4 192,341.4 192,192 341.4,192 341.4,149.4 "
                      fill="#ffffff"
                      class=""
                    ></polygon>
                  </svg>
                </div>
              </div>
            ) : (
              <>
                <div
                  class="gto-btn"
                  onClick={() => {
                    if (inCart.quantity > 0) {
                      nav("/cart");
                    } else {
                      const newCart = [
                        ...cart,
                        {
                          ...product,
                          quantity: 1,
                        },
                      ];
                      setCart(newCart);
                      setInCart({
                        ...inCart,
                        quantity: 1,
                      });
                    }
                  }}
                >
                  <a href="#" class="js-add-cart">
                    Добавить
                  </a>
                </div>
              </>
            )}
            {inCart.quantity > 0 ? (
              <div
                class="gto-btn"
                onClick={() => {
                  nav("/cart");
                }}
              >
                <a href="#" class="js-add-cart">
                  В корзину
                </a>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
